<template>
	<div>
		<div class="d-flex justify-content-end align-items-center">
			<CDropdown
				placement="bottom-end"
				data-test-id="export"
				add-menu-classes="export-dropdown-menu"
				class="btn-export pr-0 mr-3"
				color="tertiary"
			>
				<CDropdownItem @click="exportPromotion('campaign_code')">
					Promotion
				</CDropdownItem>
				<CDropdownItem @click="exportPromotion('coupon_code')">
					Coupon code
				</CDropdownItem>
				<template #toggler-content>
					Export
				</template>
			</CDropdown>
			<CDropdown
				add-menu-classes="import-dropdown-menu"
				class="btn-dropdown text-right"
				color="secondary"
			>
				<CDropdownItem
					v-for="({ value, label }, index) in promotionType"
					:key="index"
					@click="createPromotion(value)"
				>
					{{ label }}
				</CDropdownItem>
				<template #toggler-content>
					<CIcon class="mr-2" name="cil-plus" />Create Promotion
				</template>
			</CDropdown>
		</div>

		<div class="tabs-container-large mt-3 mb-5 pb-5">
			<div class="tabs-wrapper">
				<CTabs
					:active-tab="activeTab"
					@update:activeTab="handleUpdateTab"
				>
					<CTab
						v-for="({label}, index) in tabs"
						:key="index"
						:title="label"
					>
						<div class="c-main">
							<div class="container-fluid">
								<!-- Filter -->
								<CRow>
									<CCol class="d-flex align-items-start">
										<form
											class="search-form flex-fill"
											@submit.prevent="handleSearch"
										>
											<CInput
												v-model.trim="queryParams.q"
												placeholder="Search by coupon code, promotion name, and ID"
											>
												<template #prepend-content>
													<CIcon name="cil-magnifying-glass" />
												</template>
											</CInput>
										</form>
										<BaseFilterButton
											class="ml-3"
											:is-open="isShowFilter"
											:has-filter="hasFilter"
											@onClick="toggleFilter"
										/>
									</CCol>
								</CRow>

								<CRow>
									<CCol>
										<transition name="fade">
											<div
												v-if="isShowFilter"
												class="filter-box rounded-sm pt-3 px-3 mb-3"
											>
												<CRow>
													<CCol md="3">
														<BaseDropDown
															:value="getSelectedValue(
																queryParams.status,
																promotionStatusOptions,
															)"
															:searchable="false"
															:options="promotionStatusOptions"
															:allow-empty="false"
															class="select-custom"
															label="label"
															track-by="value"
															label-drop-down="Promotion status"
															@input="handlePromotionStatus"
														/>
													</CCol>
													<CCol md="3">
														<BaseInputDate
															:value="dateRange"
															label="Date"
															placeholder="All periods"
															mode="range"
															@input="handleFilterDateChange"
														>
															<template #append-content>
																<CIcon
																	class="cil-calendar"
																	name="cil-calendar"
																/>
															</template>
														</BaseInputDate>
													</CCol>
													<CCol md="3">
														<BaseDropDown
															:value="getSelectedValue(
																queryParams.period_status,
																PERIOD_STATUS_OPTIONS,
															)"
															:searchable="false"
															:options="PERIOD_STATUS_OPTIONS"
															:allow-empty="false"
															class="select-custom"
															label="label"
															track-by="value"
															label-drop-down="Period status"
															@input="handlePeriodStatus"
														/>
													</CCol>
													<CCol md="3">
														<BaseDropDown
															:value="getSelectedValue(
																queryParams.discount_type,
																DISCOUNT_TYPE_FILTER_OPTIONS,
															)"
															:searchable="false"
															:options="DISCOUNT_TYPE_FILTER_OPTIONS"
															:allow-empty="false"
															class="select-custom"
															label="label"
															track-by="value"
															label-drop-down="Discount Type"
															@input="handleDiscountType"
														/>
													</CCol>
												</CRow>
											</div>
										</transition>
									</CCol>
								</CRow>

								<CRow class="mb-4">
									<CCol>
										<BaseSearchFormFooter
											:count="list.meta.total"
											append-text="promotion(s) found"
											@onReset="resetFilter"
										/>
									</CCol>
								</CRow>

								<CRow>
									<CCol>
										<BaseTable
											:is-loading="list.isLoading"
											:fields="PROMOTIONS_TABLE_FIELDS"
											:items="data"
											:pagination="{
												pages: list.meta.lastPage,
												activePage: list.meta.currentPage,
											}"
											class="table-custom"
											striped
											clickable-rows
											link-to="PromotionEdit"
											@onPaginationClick="handlePageChange"
										>
											<template #no-items-view>
												<div class="empty-table-element">
													<p class="subtitle">
														{{ $t('global.searchNotFound', {field: 'promotion'}) }}
													</p>
												</div>
											</template>
											<template #title="{item}">
												<div>
													<p class="mb-0">{{ item.name }}</p>
													<span v-if="item.couponCodesText" class="typo-caption color-info">{{ item.couponCodesText }}</span>
												</div>
											</template>
											<template #type="{item}">
												<div>
													{{ item.name }}
												</div>
												<div class="caption">
													{{ item.reward }}
												</div>
											</template>
											<template #status="{item}">
												<CBadge
													class="badge-status"
													:color="item.value.toLowerCase()"
												>
													{{ item.label }}
												</CBadge>
											</template>
											<template #periodStatus="{item}">
												<CBadge
													class="badge-status"
													:color="item.color"
												>
													{{ item.title }}
												</CBadge>
											</template>
											<template #duplicateId="{item}">
												<CLink
													variant="ghost"
													color="secondary"
													:to="{ name: 'PromotionCreate', query: { ref: item } }"
												>
													Duplicate
												</CLink>
											</template>
										</BaseTable>
									</CCol>
								</CRow>
							</div>
						</div>
					</CTab>
				</CTabs>
			</div>
		</div>
		<BaseModalConfirm
			ref="modal-export-promotion"
			:is-submitting="isExporting"
			:close-after-confirm="false"
			title="Export report"
			description="The report will be sent to your email after combinded all the files. It might be taking for 5 - 30 mins, please be patient."
			primary-button-text="Send"
			primary-button-loading-text="Sending"
			@onConfirm="handleExportPromotion"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import { randomSearchString, cleanObject, hasSearchFilter, convertDateRangeToUTC } from '../assets/js/helpers';
import {
	PROMOTIONS_TABLE_FIELDS,
	PROMOTION_TYPE_LABELS,
	PROMOTION_STATUS_OPTIONS,
	PERIOD_STATUS_OPTIONS,
	DISCOUNT_TYPE_OPTIONS,
	DISCOUNT_TYPE_FILTER_OPTIONS,
	PROMOTION_TYPE_TABS,
	PROMOTION_TYPES,
} from '../enums/promotions';
import { postPromotionExport } from '../services/api/promotions.api';

export default {
	name: 'Promotions',

	components: {
		BaseModalConfirm,
	},

	data() {
		const { type: selectedPromotionType } = this.$route.query;
		const rawTabIndex = PROMOTION_TYPE_TABS.findIndex((type) => type.value === selectedPromotionType);

		return {
			PROMOTIONS_TABLE_FIELDS,
			PERIOD_STATUS_OPTIONS,
			DISCOUNT_TYPE_OPTIONS,
			DISCOUNT_TYPE_FILTER_OPTIONS,
			activeTab: rawTabIndex + 1, // if first tab (All promotions) is actived, So Index value will be (-1 + 1) = 0
			queryParams: {
				q: this.$route.query.q || null,
				type: this.$route.query.type || null,
				page: Number(this.$route.query.page) || null,
				status: this.$route.query.status || null,
				start_at: this.$route.query.start_at || null,
				end_at: this.$route.query.end_at || null,
				period_status: this.$route.query.period_status || null,
				discount_type: this.$route.query.discount_type || null,
				r: randomSearchString(),
			},
			isShowFilter: false,
			isExporting: false,
			exportType: null,
		};
	},

	computed: {
		...mapState('promotions', {
			list: 'list',
		}),

		...mapGetters({
			data: 'promotions/getPromotionList',
		}),

		hasFilter() {
			return hasSearchFilter(this.queryParams, ['type']);
		},

		promotionType() {
			return Object.entries(PROMOTION_TYPE_LABELS).map(([key, value]) => ({
				value: key,
				label: value,
			}));
		},

		promotionStatusOptions() {
			return [
				{
					value: null,
					label: 'All promotion status',
				},
				...PROMOTION_STATUS_OPTIONS,
			];
		},

		tabs() {
			return [
				{
					value: null,
					label: 'All Promotions',
				},
				...PROMOTION_TYPE_TABS,
			];
		},

		dateRange() {
			if (!this.queryParams.start_at || !this.queryParams.end_at) {
				return null;
			}

			return {
				start: new Date(this.queryParams.start_at),
				end: new Date(this.queryParams.end_at),
			};
		},
	},

	async created() {
		this.isShowFilter = this.hasFilter;
		await this.getPromotions(this.queryParams);
	},

	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			getPromotions: 'promotions/getPromotions',
		}),

		createPromotion(type) {
			this.$router.push({
				name: 'PromotionCreate',
				query: { ...(type !== PROMOTION_TYPES.GENERAL ? { type } : null) },
			});
		},

		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query }).catch(() => {});
		},

		getSelectedValue(selectedValue = null, options = []) {
			const optionValues = Object.values(options);
			const found = ({ value }) => value === selectedValue;

			return (
				optionValues.find(found) || {
					value: null,
					label: null,
				}
			);
		},

		handleUpdateTab(tabIndex) {
			const promotionType = (this.tabs[tabIndex] || {}).value;
			this.queryParams.page = null;
			this.queryParams.type = promotionType;
			this.updateUrlParams();
		},

		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},

		handlePromotionStatus(event) {
			this.queryParams.page = null;
			this.queryParams.status = event.value;
			this.updateUrlParams();
		},

		handleFilterDateChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.start_at = start;
			this.queryParams.end_at = end;
			this.updateUrlParams();
		},

		handlePeriodStatus(event) {
			this.queryParams.page = null;
			this.queryParams.period_status = event.value;
			this.updateUrlParams();
		},

		handleDiscountType(event) {
			this.queryParams.page = null;
			this.queryParams.discount_type = event.value;
			this.updateUrlParams();
		},

		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},

		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				promotion: null,
			};

			this.updateUrlParams();
		},

		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},

		exportPromotion(type) {
			this.exportType = type;
			this.$refs['modal-export-promotion'].open();
		},

		async handleExportPromotion() {
			this.isExporting = true;
			try {
				await postPromotionExport({
					...this.queryParams,
					report_key: this.exportType,
				});

				this.showToast({
					header: this.$t('global.successMessageTitle'),
					content: `Your request has been completed.`,
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					header: this.$t('global.errorMessageExport'),
					content: this.$t('global.errorMessage'),
					type: 'danger',
				});
			} finally {
				this.$refs['modal-export-promotion'].close();
				this.isExporting = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.filter-box {
		background-color: $color-gray-100;
	}

	.caption {
		font-size: rem($font-size-caption);
		font-weight: $font-weight-bold;
		color: $color-black-45;
	}

	// Set placeholder of filter by date range
	::v-deep .form-control-date {
		input[type="input"] {
			@include placeholder-black;
		}
	}
</style>
