var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('CDropdown',{staticClass:"btn-export pr-0 mr-3",attrs:{"placement":"bottom-end","data-test-id":"export","add-menu-classes":"export-dropdown-menu","color":"tertiary"},scopedSlots:_vm._u([{key:"toggler-content",fn:function(){return [_vm._v(" Export ")]},proxy:true}])},[_c('CDropdownItem',{on:{"click":function($event){return _vm.exportPromotion('campaign_code')}}},[_vm._v(" Promotion ")]),_c('CDropdownItem',{on:{"click":function($event){return _vm.exportPromotion('coupon_code')}}},[_vm._v(" Coupon code ")])],1),_c('CDropdown',{staticClass:"btn-dropdown text-right",attrs:{"add-menu-classes":"import-dropdown-menu","color":"secondary"},scopedSlots:_vm._u([{key:"toggler-content",fn:function(){return [_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-plus"}}),_vm._v("Create Promotion ")]},proxy:true}])},_vm._l((_vm.promotionType),function(ref,index){
var value = ref.value;
var label = ref.label;
return _c('CDropdownItem',{key:index,on:{"click":function($event){return _vm.createPromotion(value)}}},[_vm._v(" "+_vm._s(label)+" ")])}),1)],1),_c('div',{staticClass:"tabs-container-large mt-3 mb-5 pb-5"},[_c('div',{staticClass:"tabs-wrapper"},[_c('CTabs',{attrs:{"active-tab":_vm.activeTab},on:{"update:activeTab":_vm.handleUpdateTab}},_vm._l((_vm.tabs),function(ref,index){
var label = ref.label;
return _c('CTab',{key:index,attrs:{"title":label}},[_c('div',{staticClass:"c-main"},[_c('div',{staticClass:"container-fluid"},[_c('CRow',[_c('CCol',{staticClass:"d-flex align-items-start"},[_c('form',{staticClass:"search-form flex-fill",on:{"submit":function($event){$event.preventDefault();return _vm.handleSearch($event)}}},[_c('CInput',{attrs:{"placeholder":"Search by coupon code, promotion name, and ID"},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-magnifying-glass"}})]},proxy:true}],null,true),model:{value:(_vm.queryParams.q),callback:function ($$v) {_vm.$set(_vm.queryParams, "q", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryParams.q"}})],1),_c('BaseFilterButton',{staticClass:"ml-3",attrs:{"is-open":_vm.isShowFilter,"has-filter":_vm.hasFilter},on:{"onClick":_vm.toggleFilter}})],1)],1),_c('CRow',[_c('CCol',[_c('transition',{attrs:{"name":"fade"}},[(_vm.isShowFilter)?_c('div',{staticClass:"filter-box rounded-sm pt-3 px-3 mb-3"},[_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[_c('BaseDropDown',{staticClass:"select-custom",attrs:{"value":_vm.getSelectedValue(
															_vm.queryParams.status,
															_vm.promotionStatusOptions
														),"searchable":false,"options":_vm.promotionStatusOptions,"allow-empty":false,"label":"label","track-by":"value","label-drop-down":"Promotion status"},on:{"input":_vm.handlePromotionStatus}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('BaseInputDate',{attrs:{"value":_vm.dateRange,"label":"Date","placeholder":"All periods","mode":"range"},on:{"input":_vm.handleFilterDateChange},scopedSlots:_vm._u([{key:"append-content",fn:function(){return [_c('CIcon',{staticClass:"cil-calendar",attrs:{"name":"cil-calendar"}})]},proxy:true}],null,true)})],1),_c('CCol',{attrs:{"md":"3"}},[_c('BaseDropDown',{staticClass:"select-custom",attrs:{"value":_vm.getSelectedValue(
															_vm.queryParams.period_status,
															_vm.PERIOD_STATUS_OPTIONS
														),"searchable":false,"options":_vm.PERIOD_STATUS_OPTIONS,"allow-empty":false,"label":"label","track-by":"value","label-drop-down":"Period status"},on:{"input":_vm.handlePeriodStatus}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('BaseDropDown',{staticClass:"select-custom",attrs:{"value":_vm.getSelectedValue(
															_vm.queryParams.discount_type,
															_vm.DISCOUNT_TYPE_FILTER_OPTIONS
														),"searchable":false,"options":_vm.DISCOUNT_TYPE_FILTER_OPTIONS,"allow-empty":false,"label":"label","track-by":"value","label-drop-down":"Discount Type"},on:{"input":_vm.handleDiscountType}})],1)],1)],1):_vm._e()])],1)],1),_c('CRow',{staticClass:"mb-4"},[_c('CCol',[_c('BaseSearchFormFooter',{attrs:{"count":_vm.list.meta.total,"append-text":"promotion(s) found"},on:{"onReset":_vm.resetFilter}})],1)],1),_c('CRow',[_c('CCol',[_c('BaseTable',{staticClass:"table-custom",attrs:{"is-loading":_vm.list.isLoading,"fields":_vm.PROMOTIONS_TABLE_FIELDS,"items":_vm.data,"pagination":{
											pages: _vm.list.meta.lastPage,
											activePage: _vm.list.meta.currentPage,
										},"striped":"","clickable-rows":"","link-to":"PromotionEdit"},on:{"onPaginationClick":_vm.handlePageChange},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"empty-table-element"},[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('global.searchNotFound', {field: 'promotion'}))+" ")])])]},proxy:true},{key:"title",fn:function(ref){
										var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.name))]),(item.couponCodesText)?_c('span',{staticClass:"typo-caption color-info"},[_vm._v(_vm._s(item.couponCodesText))]):_vm._e()])]}},{key:"type",fn:function(ref){
										var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.reward)+" ")])]}},{key:"status",fn:function(ref){
										var item = ref.item;
return [_c('CBadge',{staticClass:"badge-status",attrs:{"color":item.value.toLowerCase()}},[_vm._v(" "+_vm._s(item.label)+" ")])]}},{key:"periodStatus",fn:function(ref){
										var item = ref.item;
return [_c('CBadge',{staticClass:"badge-status",attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"duplicateId",fn:function(ref){
										var item = ref.item;
return [_c('CLink',{attrs:{"variant":"ghost","color":"secondary","to":{ name: 'PromotionCreate', query: { ref: item } }}},[_vm._v(" Duplicate ")])]}}],null,true)})],1)],1)],1)])])}),1)],1)]),_c('BaseModalConfirm',{ref:"modal-export-promotion",attrs:{"is-submitting":_vm.isExporting,"close-after-confirm":false,"title":"Export report","description":"The report will be sent to your email after combinded all the files. It might be taking for 5 - 30 mins, please be patient.","primary-button-text":"Send","primary-button-loading-text":"Sending"},on:{"onConfirm":_vm.handleExportPromotion}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }